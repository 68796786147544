/* eslint-disable react/no-array-index-key */
/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['wrapper'] }] */
import { ElementType, Fragment } from 'react';
import Link from 'next/link';
import slugg from 'slugg';
import classNames from 'classnames';

import { EMAIL_FORMAT, LINK_MARKUP_FORMAT } from 'consts/validation';
import ContactConfig from 'types/ContactConfig';
import { Description, DescriptionBlock, MentionContent, MixedContent, TextContent } from 'types/Description';
import { CardType } from '@components/ProductsList';
import HtmlPreview from '@components/HtmlPreview';
import resolveMarkups from 'utils/resolveMarkups';
import compact from 'utils/compact';
import keysOf from 'utils/keysOf';

import Header from './components/Header';
import Paragraph from './components/Paragraph';
import CodeBlock from './components/CodeBlock';
import List from './components/List';
import Table from './components/Table';
import Widget, { classes as widgetClasses } from './components/Widget';
import classes from './Description.module.scss';

const checkIfHasBoldMark = (item: MentionContent | TextContent): boolean =>
  !!item.marks?.find(({ type }) => type === 'bold');

const checkIfHasLinkMark = (item: MentionContent | TextContent): boolean =>
  !!item.marks?.find(({ type }) => type === 'link');

const getTextItemComponent = (
  item: MentionContent | TextContent
): { Component: ElementType; props?: Record<string, unknown> } => {
  if (checkIfHasBoldMark(item)) {
    return { Component: 'b' };
  }

  if (checkIfHasLinkMark(item)) {
    const { marks } = item as TextContent;
    const linkMark = marks?.find(({ type }) => type === 'link');

    return {
      Component: Link,
      props: {
        rel: 'noopener noreferrer nofollow',
        href: linkMark?.attrs.href,
        target: linkMark?.attrs.href,
        className: widgetClasses.link,
      },
    };
  }

  return { Component: Fragment };
};

const getInlineVariantCardsType = (item: DescriptionBlock): CardType | undefined => {
  const inlineVariantCard =
    item.content?.find((contentItem) => (contentItem as MentionContent).attrs?.id === 'VARIANT_CARD') ||
    item.content?.find((contentItem) => {
      const payload = JSON.parse((contentItem as MentionContent).attrs?.['data-payload'] || '{}');
      return (contentItem as MentionContent).attrs?.id === 'VARIANTS_CARDS' && Number(payload?.limit) === 1;
    });
  return JSON.parse((inlineVariantCard as MentionContent)?.attrs?.['data-payload'] || '{}').cardType;
};

const getParagraphElement = (item: DescriptionBlock): ElementType =>
  !(item.content as MentionContent[])?.find(
    ({ type, attrs }) =>
      type === 'mentionAtom' &&
      ['VARIANTS_CARDS', 'VARIANT_CARD', 'RANKING_VARIANT', 'PRODUCT_VIDEO'].includes(attrs?.id)
  )
    ? 'p'
    : 'div';

const getContentWithTrailingBreaks = (item: DescriptionBlock): MixedContent[] =>
  item.content?.flatMap((element, j) =>
    element.type === 'hardBreak' && item.content?.[j + 1]?.type !== 'hardBreak' ? [element, element] : element
  ) || [];

const getWidgetTextContent = (block: DescriptionBlock): string => {
  const payload = JSON.parse((block as unknown as MentionContent).attrs['data-payload']);

  return payload.label || '';
};

export const getHeadings = (description: Description | DescriptionBlock | MixedContent | undefined, level: number) =>
  (description as DescriptionBlock)?.content?.filter((descriptionItem) => {
    const descriptionBlock = descriptionItem as unknown as DescriptionBlock;
    return descriptionBlock.type === 'heading' && descriptionBlock.attrs.level === level;
  });

export const getPlainTextContent = (
  description?: Description | DescriptionBlock | MixedContent,
  separator = '\n'
): string =>
  compact(
    (description as Description)?.content?.map((block) => {
      const blockText = (block as unknown as TextContent).text || '';

      if (blockText) {
        return blockText === ' ' ? '' : blockText;
      }

      return (
        (block as unknown as TextContent).text ||
        ((block as unknown as MentionContent).type === 'mentionAtom' && getWidgetTextContent(block)) ||
        ((block as unknown as Omit<DescriptionBlock, 'id'>).content && getPlainTextContent(block, ' '))
      );
    }) || []
  )
    .filter((part) => !!part.length)
    .map((part) => part.trim())
    .join(separator);

export const replaceMarkersInDescription = (
  description: Description | DescriptionBlock | MixedContent,
  contactConfig: ContactConfig
): Description => {
  (description as Description)?.content?.forEach((block) => {
    const newBlock = replaceMarkersInDescription(block, contactConfig);

    (newBlock as unknown as TextContent).text = keysOf(contactConfig).reduce(
      (acc, marker) => acc.replace(new RegExp(`{{${marker}}}`, 'g'), `${contactConfig[marker]}` || `{{${marker}}}`),
      (newBlock as unknown as TextContent).text || ''
    );

    return newBlock;
  });

  return description as Description;
};

export const getDescription = ({
  appUrl,
  contact,
  content = [],
  renderFakeHeaders = false,
  raw = false,
  withoutLinks = false,
  tableOfContentsLevels = [],
}: {
  appUrl: string;
  contact: ContactConfig;
  content: Nullable<(DescriptionBlock | MixedContent)[]>;
  renderFakeHeaders?: boolean;
  raw?: boolean;
  withoutLinks?: boolean;
  tableOfContentsLevels?: number[];
}): (JSX.Element | null)[] =>
  content?.map((item, i) => {
    switch (item.type) {
      case 'heading': {
        const contentWithTrailingBreaks = getContentWithTrailingBreaks(item);

        return (
          <Header
            key={`heading-${i}`}
            id={
              (tableOfContentsLevels.includes(item.attrs.level) &&
                item.content &&
                slugg(getPlainTextContent(item, ' '))) ||
              undefined
            }
            size={item.attrs.level}
            align={item.attrs.nodeTextAlignment}
            renderAs={renderFakeHeaders ? 'div' : undefined}
          >
            {getDescription({
              appUrl,
              contact,
              content: contentWithTrailingBreaks,
              renderFakeHeaders,
              raw,
              withoutLinks,
              tableOfContentsLevels,
            })}
          </Header>
        );
      }
      case 'paragraph': {
        const contentWithTrailingBreaks = getContentWithTrailingBreaks(item);
        const paragraphElement = getParagraphElement(item);
        const inlineVariantCardsType = getInlineVariantCardsType(item);
        return (
          <Paragraph
            key={`paragraph-${i}`}
            className={
              inlineVariantCardsType
                ? classNames(classes.paragraphWithCards, { [classes.smallCards]: inlineVariantCardsType === 'SMALL' })
                : undefined
            }
            align={item.attrs.nodeTextAlignment}
            renderAs={paragraphElement}
          >
            {getDescription({
              appUrl,
              contact,
              content: contentWithTrailingBreaks,
              renderFakeHeaders,
              raw,
              withoutLinks,
              tableOfContentsLevels,
            })}
          </Paragraph>
        );
      }
      case 'codeBlock': {
        const plainTextContent = getPlainTextContent(item, '\n');
        return <CodeBlock key={`codeBlock-${i}`} language={item.attrs.language} content={plainTextContent} />;
      }
      case 'bulletList':
      case 'orderedList':
        return <List key={`list-${i}`} style={item.type} items={item.content} renderFakeHeaders={renderFakeHeaders} />;
      case 'table': {
        const meta = item.attrs['data-meta'] || { borders: false };
        return (
          <Table
            key={`table-${i}`}
            rows={item.content}
            renderFakeHeaders={renderFakeHeaders}
            withBorders={meta.borders}
            width={meta.width}
          />
        );
      }
      case 'mentionAtom': {
        const payload = JSON.parse(item.attrs['data-payload']);
        const Component = checkIfHasBoldMark(item) ? 'b' : Fragment;

        return (
          <Component key={`mentionAtom-${i}`}>
            <Widget id={payload.id} type={item.attrs.id} payload={payload} raw={raw} withoutLinks={withoutLinks} />
          </Component>
        );
      }
      case 'text': {
        const { Component, props } = getTextItemComponent(item);
        const text = props?.href
          ? resolveMarkups(item.text, { ...contact, appUrl })
          : resolveMarkups(item.text, { ...contact, appUrl })
              .replace(new RegExp(EMAIL_FORMAT, 'g'), `<a href="mailto:$1" class="${widgetClasses.link}">$1</a>`)
              .replace(new RegExp(LINK_MARKUP_FORMAT, 'g'), `<a href="$2" class="${widgetClasses.link}">$1</a>`);

        return (
          <Component key={`text-${i}`} {...props}>
            <HtmlPreview htmlText={text} component="span" />
          </Component>
        );
      }
      case 'hardBreak': {
        return <br key={`hardBreak-${i}`} />;
      }
      case 'horizontalRule': {
        return <hr key={`horizontalRule-${i}`} className={classes.divider} />;
      }
      default:
        return null;
    }
  }) || [];

export const checkDescriptionIsNotEmpty = (description?: Description | DescriptionBlock | MixedContent): boolean =>
  !!(description as Description)?.content?.some(
    (block) =>
      (block as unknown as TextContent).text ||
      (block as unknown as MentionContent).type === 'mentionAtom' ||
      ((block as unknown as Omit<DescriptionBlock, 'id'>).content && checkDescriptionIsNotEmpty(block))
  );
